<template>
    <div class="create-plan-content">
        <div class="plan-step-one-header">
            <span>推广类型</span>
            <span>创建计划成功后，计划类型不可更改</span>
        </div>
        <div class="plan-step-one-type">
            <div class="type-item" @click="toNext">
                <i class="iconfont">&#xe69e;</i>
                <span class="span1">商品推广</span>
                <span class="span2">用于推广您店铺下的商品，适用于如下场景：打造爆款，推广新品及日常销售等。</span>
            </div>
        </div>
        <router-link :to="{name:'operationPromotionIndex'}"><el-button class="close-btn">取消</el-button></router-link>
    </div>
</template>

<script>
    export default {
        name: "CreatePlanStepOne",
        data(){
            return {

            }
        },
        methods: {
            toNext(){
                let param = {
                    path:'/student/operationPromotion/createPlanStepTwo'
                };
                if (this.$route.query.id) {
                    param.query = {
                        id: this.$route.query.id
                    }
                }
                this.$router.push(param);
            }
        }
    }
</script>

<style scoped lang="scss">
    .create-plan-content{
        height: calc(100vh - 200px);
        padding: 20px 30px;
        /*display: flex;*/
        /*flex-direction: column;*/
        .plan-step-one-header{
            span:nth-child(1){
                color: #333333;
                font-size: 28px;
                font-weight: 500;
            }
            span:nth-child(2){
                color: #959697;
                font-size: 16px;
                font-weight: 500;
                margin-left: 20px;
            }
        }
        .plan-step-one-type{
            margin-top: 48px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            color: #333;
            .type-item {
                width: calc(50% - 96px);
                background: #F5F5F5;
                padding: 40px 40px 36px;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                &:nth-child(2n+1) {
                    /*margin-bottom: 40px;*/
                }
                &:hover {
                    color: #FD4446;
                    i {
                        font-size: 40px;
                        color: #FD4446;
                    }
                }
            }
            i {
                font-size: 40px;
                color: #DFDFDF;
            }
            .span1{
                font-size: 24px;
                font-weight: 500;
                padding-top: 30px;
            }
            .span2{
                font-size: 16px;
                font-weight: 400;
                padding-top: 30px;
            }

        }
        .close-btn{
            width: 140px;
            margin-top: 50px;
            color: #333333;
            font-size: 20px;
        }
    }
</style>
